import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/mes_cloud_mock/xisha',
    name: 'mes_cloud_mock_xisha',
    component: () => import('../views/mes_cloud_mock/XishaView.vue')
  },
  {
    path: '/mes_cloud_mock/luotong',
    name: 'mes_cloud_mock_luotong',
    component: () => import('../views/mes_cloud_mock/LuotongView.vue')
  },
  {
    path: '/mes_cloud_mock/temp',
    name: 'mes_cloud_mock_temp',
    component: () => import('../views/mes_cloud_mock/TempView.vue')
  },
  {
    path: '/mes_cloud_mock/energy',
    name: 'mes_cloud_mock_energy',
    component: () => import('../views/mes_cloud_mock/EnergyView.vue')
  },
  {
    path: '/mes_cloud_mock/qingshaqi',
    name: 'mes_cloud_mock_qingshaqi',
    component: () => import('../views/mes_cloud_mock/QingshaqiView.vue')
  },
  {
    path: '/mes_cloud_mock/maoyuyi',
    name: 'mes_cloud_mock_maoyuyi',
    component: () => import('../views/mes_cloud_mock/MaoyuyiView.vue')
  },
  {
    path: '/mes_cloud_mock/tiaoganyi',
    name: 'mes_cloud_mock_tiaoganyi',
    component: () => import('../views/mes_cloud_mock/TiaoganyiView.vue')
  },
  {
    path: '/mes_cloud_mock/qiangliyi',
    name: 'mes_cloud_mock_qiangliyi',
    component: () => import('../views/mes_cloud_mock/QiangliyiView.vue')
  },
  {
    path: '/mes_cloud_mock/afis',
    name: 'mes_cloud_mock_afis',
    component: () => import('../views/mes_cloud_mock/AfisView.vue')
  },
  {
    path: '/other/show_log',
    name: 'other_showlog',
    component: () => import('../views/other/ShowLogView.vue')
  },
  {
    path: '/other/show_history',
    name: 'other_showhistory',
    component: () => import('../views/other/ShowHistoryView.vue')
  },
  {
    path: '/other/show_running_machine',
    name: 'other_showrunningmachine',
    component: () => import('../views/other/ShowRunningMachineView.vue')
  },
  {
    path: '/data_statistics/my_version',
    name: 'data_statistics_my_version',
    component: () => import('../views/data_statistics/MyVersion.vue')
  },
  {
    path: '/data_statistics/tester_bug',
    name: 'data_statistics_tester_bug',
    component: () => import('../views/data_statistics/TesterBug.vue')
  },
  {
    path: '/data_statistics/developer_bug',
    name: 'data_statistics_developer_bug',
    component: () => import('../views/data_statistics/DeveloperBug.vue')
  },
  {
    path: '/data_statistics/weekly_paper',
    name: 'data_statistics_weekly_paper',
    component: () => import('../views/data_statistics/weekly_paper.vue')
  },
  {
    path: '/data_statistics/bug_situation',
    name: 'data_statistics_bug_situation',
    component: () => import('../views/data_statistics/BugSituation.vue')
  },
  {
    path: '/data_statistics/version_modify_rate',
    name: 'data_statistics_version_modify_rate',
    component: () => import('../views/data_statistics/VersionModifyRate.vue')
  },
  {
    path: '/qcr_cloud_mock/work_order_create',
    name: 'qcr_cloud_mock_work_order_create',
    component: () => import('../views/qcr_cloud_mock/WorkOrderCreateView.vue')
  },
  {
    path: '/qcr_cloud_mock/work_order_create_and_solve',
    name: 'qcr_cloud_mock_work_order_create_and_solve',
    component: () => import('../views/qcr_cloud_mock/WorkOrderCreateAndSolveView.vue')
  },
  {
    path: '/qcr_cloud_mock/defect',
    name: 'qcr_cloud_mock_defect',
    component: () => import('../views/qcr_cloud_mock/DefectView.vue')
  },
  {
    path: '/qcr_cloud_mock/work_order_solve',
    name: 'qcr_cloud_mock_work_order_solve',
    component: () => import('../views/qcr_cloud_mock/WorkOrderSolveView.vue')
  },
  {
    path: '/qcr_cloud_mock/cloth_edge_filtration',
    name: 'qcr_cloud_mock_cloth_edge_filtration',
    component: () => import('../views/qcr_cloud_mock/ClothEdgeFiltrationView.vue')
  },
  {
    path: '/qcr_cloud_mock/start_quality',
    name: 'qcr_cloud_mock_start_quality',
    component: () => import('../views/qcr_cloud_mock/StartQualityView.vue')
  },
  {
    path: '/qcr_cloud_mock/quality_new',
    name: 'qcr_cloud_mock_quality_new',
    component: () => import('../views/qcr_cloud_mock/QualityNewView.vue')
  },
  {
    path: '/qcr_cloud_mock/start_small_cloth_quality',
    name: 'qcr_cloud_mock_start_small_cloth_quality',
    component: () => import('../views/qcr_cloud_mock/StartSmallClothQualityView.vue')
  },
  {
    path: '/qcr_cloud_mock/small_cloth_quality',
    name: 'qcr_cloud_mock_small_cloth_quality',
    component: () => import('../views/qcr_cloud_mock/SmallClothQualityView.vue')
  },
  {
    path: '/qcr_cloud_mock/all_process',
    name: 'qcr_cloud_mock_all_process',
    component: () => import('../views/qcr_cloud_mock/AllProcessView.vue')
  },
  {
    path: '/qcr_cloud_mock/weaved_qr_code',
    name: 'qcr_cloud_mock_weaved_qr_code',
    component: () => import('../views/qcr_cloud_mock/WeavedQRCodeView.vue')
  },
  {
    path: '/qcr_cloud_mock/recall_alert',
    name: 'qcr_cloud_mock_recall_alert',
    component: () => import('../views/qcr_cloud_mock/RecallAlertView.vue')
  },
  {
    path: '/qcr_cloud_mock/alert',
    name: 'qcr_cloud_mock_alert',
    component: () => import('../views/qcr_cloud_mock/AlertView.vue')
  },
  {
    path: '/qcr_cloud_mock/defect_merge',
    name: 'qcr_cloud_mock_defect_merge',
    component: () => import('../views/qcr_cloud_mock/DefectMergeView.vue')
  },
  {
    path: '/qcr_cloud_mock/up_run_mode',
    name: 'qcr_cloud_mock_up_run_mode',
    component: () => import('../views/qcr_cloud_mock/UpRunModeView.vue')
  },
  {
    path: '/qcr_cloud_mock/machine_detail_info',
    name: 'qcr_cloud_mock_machine_detail_info',
    component: () => import('../views/qcr_cloud_mock/MachineDetailInfoView.vue')
  },
  {
    path: '/qcr_cloud_mock/mock_send',
    name: 'qcr_cloud_mock_mock_send',
    component: () => import('../views/qcr_cloud_mock/MockView.vue')
  },
  {
    path: '/qcr_cloud_mock/online_data_reflow',
    name: 'qcr_cloud_mock_online_data_reflow',
    component: () => import('../views/qcr_cloud_mock/OnlineDataReflow.vue')
  },
  {
    path: '/qcr_cloud_mock/bigdata_monitor',
    name: 'qcr_cloud_mock_bigdata_monitor',
    component: () => import('../views/qcr_cloud_mock/BigdataMonitor.vue')
  },
  {
    path: '/qcr_cloud_mock/cloth_inspect',
    name: 'qcr_cloud_mock_cloth_inspect',
    component: () => import('../views/qcr_cloud_mock/ClothInspect.vue')
  },
  {
    path: '/qcr_cloud_mock_v2/mock_send',
    name: 'qcr_cloud_mock_v2_mock_send',
    component: () => import('../views/qcr_cloud_mock_v2/MockView.vue')
  },
  {
    path: '/qcr_cloud_mock_v2/send_event',
    name: 'qcr_cloud_mock_v2_send_event',
    component: () => import('../views/qcr_cloud_mock_v2/SendEventView.vue')
  },
  {
    path: '/biaobang',
    name: 'biaobang',
    component: () => import('../views/biaobang.vue')
  },
  {
    path: '/qcr_test_tool/gemi_downstream',
    name: 'qcr_test_tool_gemi_downstream',
    component: () => import('../views/qcr_test_tool/gemi_downstream.vue')
  },
  {
    path: '/qcr_test_tool/gemi_batch_downstream',
    name: 'qcr_test_tool_gemi_batch_downstream',
    component: () => import('../views/qcr_test_tool/gemi_batch_downstream.vue')
  },
  {
    path: '/qcr_test_tool/get_version',
    name: 'qcr_test_tool_get_version',
    component: () => import('../views/qcr_test_tool/GetVersionView.vue')
  },
  {
    path: '/qcr_test_tool/kafka_send',
    name: 'qcr_test_tool_kafka_send',
    component: () => import('../views/qcr_test_tool/KafkaSendView.vue')
  },
  {
    path: '/qcr_test_tool/mqtt_send',
    name: 'qcr_test_tool_mqtt_send',
    component: () => import('../views/qcr_test_tool/MqttSendView.vue')
  },
  {
    path: '/qcr_test_tool/onekey_copy_config',
    name: 'qcr_test_tool_onekey_copy_config',
    component: () => import('../views/qcr_test_tool/OneKeyCopyConfig.vue')
  },
  {
    path: '/qcr_test_tool/analysis_sdk_log',
    name: 'qcr_test_tool_analysis_sdk_log',
    component: () => import('../views/qcr_test_tool/analysis_sdk_log.vue')
  },
  {
    path: '/qcr_test_tool/download_sdk_log',
    name: 'qcr_test_tool_download_sdk_log',
    component: () => import('../views/qcr_test_tool/download_sdk_log.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
